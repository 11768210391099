import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/circleci/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const BannerAlertBox = makeShortcode("BannerAlertBox");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "trip-patterns-api"
    }}>{`Trip patterns API`}</h1>
    <BannerAlertBox variant="warning" mdxType="BannerAlertBox">
    <p>
        This version of the journey planner API has now reached end-of-life and
        is no longer available. Please use the{' '}
        <a href="/pages-journeyplanner-journeyplanner">v3 API</a>.
    </p>
    </BannerAlertBox>
    <BannerAlertBox variant="warning" mdxType="BannerAlertBox">
    <p>
        The Journey Planner v3 API has no direct replacement for replace-leg functionality. You should
        use `nextLegs` and/or `previousLegs` to fetch additional departures, and implement the replace
        logic yourself.
    </p>
    </BannerAlertBox>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      